<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.227 19.227"
    aria-hidden="true"
  >
    <path
      id="Path_162"
      data-name="Path 162"
      d="M16.133,5.188h0a1.106,1.106,0,1,0,1.106,1.106A1.106,1.106,0,0,0,16.133,5.188Zm4.238,2.23a6.993,6.993,0,0,0-.424-2.239,4.551,4.551,0,0,0-1.069-1.631,4.33,4.33,0,0,0-1.631-1.06,6.726,6.726,0,0,0-2.239-.433C14.033,2,13.719,2,11.213,2s-2.819,0-3.8.055a6.726,6.726,0,0,0-2.239.433,4.4,4.4,0,0,0-1.631,1.06,4.33,4.33,0,0,0-1.06,1.631,6.726,6.726,0,0,0-.433,2.239C2,8.394,2,8.707,2,11.213s0,2.819.055,3.8a6.726,6.726,0,0,0,.433,2.239,4.33,4.33,0,0,0,1.06,1.631,4.4,4.4,0,0,0,1.631,1.06,6.726,6.726,0,0,0,2.239.433c.977.055,1.29.055,3.8.055s2.819,0,3.8-.055a6.726,6.726,0,0,0,2.239-.433,4.33,4.33,0,0,0,1.631-1.06,4.469,4.469,0,0,0,1.069-1.631,6.993,6.993,0,0,0,.424-2.239c0-.977.055-1.29.055-3.8S20.427,8.394,20.372,7.417ZM18.713,14.9a5.169,5.169,0,0,1-.313,1.714,2.819,2.819,0,0,1-.691,1.06,2.939,2.939,0,0,1-1.06.691,5.169,5.169,0,0,1-1.714.313c-.921.046-1.262.055-3.685.055s-2.764,0-3.685-.055A5.279,5.279,0,0,1,5.778,18.4a3.013,3.013,0,0,1-1.013-.691,2.764,2.764,0,0,1-.682-1.06A5.1,5.1,0,0,1,3.714,14.9c0-.921-.055-1.262-.055-3.685s0-2.764.055-3.685a5.1,5.1,0,0,1,.322-1.751,2.764,2.764,0,0,1,.728-1.013,2.893,2.893,0,0,1,1.013-.737,5.279,5.279,0,0,1,1.751-.313c.921,0,1.262-.055,3.685-.055s2.764,0,3.685.055a5.169,5.169,0,0,1,1.714.313,2.819,2.819,0,0,1,1.1.737A2.819,2.819,0,0,1,18.4,5.778a5.169,5.169,0,0,1,.313,1.751c.046.921.055,1.262.055,3.685S18.759,13.977,18.713,14.9Zm-7.5-8.412a4.717,4.717,0,1,0,3.351,1.378,4.726,4.726,0,0,0-3.351-1.378Zm0,7.795a3.068,3.068,0,1,1,3.068-3.068A3.068,3.068,0,0,1,11.213,14.282Z"
      transform="translate(-1.6 -1.6)"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.8"
      class="transition-colors duration-150"
    />
  </svg>
</template>
